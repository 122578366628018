import { useEffect } from "react";

const MidnightCacheCleaner = () => {
  // Function to clear all cache
  const clearAllCache = async () => {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        await caches.delete(cacheName);
        // console.log(`Cache ${cacheName} cleared.`);
      }
    }
    // else {
    //   console.log("Caches API is not supported in this browser.");
    // }
  };

  // Function to calculate time until midnight
  const timeUntilMidnight = () => {
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0); // Set time to next midnight
    return midnight - now; // Time remaining in milliseconds
  };

  useEffect(() => {
    // Clear cache immediately (optional) and set timer for midnight
    clearAllCache();

    // Schedule cache clearance at midnight
    const timer = setTimeout(() => {
      clearAllCache(); // Clear cache at midnight

      // Set an interval to clear cache every 24 hours
      setInterval(clearAllCache, 24 * 60 * 60 * 1000); // Every 24 hours
    }, timeUntilMidnight());

    // Cleanup the timeout on unmount
    return () => clearTimeout(timer);
  }, []);

  return null; // This component does not render anything
};

export default MidnightCacheCleaner;
